export const en = {
  cancel: 'Cancel',
  'go.whitelist': 'Participate in whitelisting',
  'modal.minting.title': 'Mint ',
  'modal.minting.subtitle': 'Do you want to participate in whitelisting?',
  'modal.minting.text':
    'Participate in DIGIMARINE whitelisting, and you will get a chance to enjoy minting your own DIGIMARINE NFT and also receive an airdrop of Mineral “B.” ',
  'main.description':
    'DIGIMARINE is a series of PFP (Picture for Profile) NFT. \n In the DIGIMARINE Universe, a fierce and endless war is taking place among its occupant species. They are all claiming their ownership over Mineral “B,” a resource very rare in this universe. The occupant species of DIGIMARINE Universe may look very cute, but beware for they are expert warriors thoroughly trained to win victory at all cost. Let’s learn more about the dynamic stories and heroes of DIGIMARINE filled with charms that are lovely and cute, but sometimes also very rough and dangerous.',
  'view.on.opensea': 'View on Opensea',
  'mint.nfts': 'Mint NFTs',
  features: 'FEATURES',
  'features.hand': 'HAND - DRAWN ART',
  'features.description':
    'DIGIMARINE is a series of hand-drawn PFP NFT. The main characters’ design is based on six marine animals - Mobydick, Cachalot, Shark, Dolphin, Tuna and Shrimp. With special properties applied, these main characters can be diversified into 10,000  unique NFT characters known as fierce warriors fighting in the DIGIMARINE Universe. Such dynamic actions inside the DIGIMARINE Universe somewhat resemble the frenzy of our own cryptocurrency ecosystem. DIGIMARINE characters are always at war with each other to win ownership over Mineral “B.” What is this Mineral “B?” The answer is soon to be revealed.',
  universe: 'DIGIMARINE UNIVERSE',
  'universe.box1.title': 'Background',
  'universe.box1.description':
    ' In the year of 3,802 AD, Digi-earth is a metaverse planet six light-years away from our world. Completely covered with blue ocean, Digi-earth suffers severe depletion of mineral resources which were used to sustain the highly-advanced science civilization of its occupants. Now, the existence of all life on Digi-earth is at stake as the planet faces  the Great Famine.',
  'universe.box2.title': 'Prelude',
  'universe.box2.description':
    'Highly civilized, the few surviving species of Digi-earth are fiercely fighting with each other to claim ownership over the remaining resources. Under such circumstances, a rumor of an anonymous mineralist discovering a very scarce resource called Mineral “B” spreads around. It is also told that Mineral “B” generates unlimited energy, making every species of Digi-earth enter a huge war of finding and claiming the sole ownership of this mysterious mineral.',
  'universe.box3.title': 'Endless war',
  'universe.box3.description':
    'After a long war among all species, only six species now survive on Digi-earth. Who will be the final winner of Mineral “B?” The Final War begins as a loud gunshot echoes the planet.',
  ecosystem: 'ECOSYSTEM',
  'ecosystem.whale': 'MOBYDICK',
  'ecosystem.whale.description':
    'MOBYDICK is the greatest predator in the DIGIMARINE Ecosystem. The enormous and powerful body of MOBYDICK can instantly destroy everything in its vicinity. All other lifeforms of Digi-earth tremble before the presence of MOBYDICK.',
  'ecosystem.sperm': 'CACHALOT',
  'ecosystem.sperm.description':
    'The size and power of Cachalots are great enough to hunt down other species, although they can’t match those of MOBYDICK. Their huge mouths suck up almost everything in their vicinity. From time to time, cachalots may hang out with MOBYDICK to build good relationships.',
  'ecosystem.shark': 'SHARK',
  'ecosystem.shark.description':
    'Sharks are species optimized for hunting other species. Sometimes, they will even hunt cachalots. Sharks are extremely reckless and destructive. Once they enter into battles, no one can stop them.',
  'ecosystem.dolphin': 'DOLPHIN',
  'ecosystem.dolphin.description':
    'Dolphins have great intelligence and social skills, allowing them to form a huge group with other  fellow dolphins. Even in battles, dolphins will fight in groups powerful enough to almost match the great MOBYDICK. Dolphins are also natural-born strategists in the Digi-earth Ecosystem. There are even reports of solitary MOBYDICK being hunted down by groups of Dolphins.',
  'ecosystem.tuna': 'TUNA',
  'ecosystem.tuna.description':
    'Tuna is the most agile and die-hard species of Digi-earth. Filled with energy, it can swim great distances at unbelievable speeds without taking a break. Other species of Digi-earth can only dream of having such mobility.',
  'ecosystem.shrimp': 'SHRIMP',
  'ecosystem.shrimp.description':
    'Although shrimps can not overpower other species in battlefields, they have outstanding vitality as well as unparalleled abilities required for escapes and camouflages. Shrimps also have super social powers and can get along well with other shrimps. Such great properties of shrimps led to the survival of the species, and even to the increase of population.',
  nftb: 'Stake your NFT and win the scarce Mineral “B” as a reward!',
  'nftb.shrimp': 'SHRIMP',
  'nftb.tuna': 'TUNA',
  'nftb.dolphin': 'DOLPHIN',
  'nftb.shark': 'SHARK',
  'nftb.sperm': 'CACHALOT',
  'nftb.whale': 'MOBYDICK',
  'digimarine.sea.story': 'DIGI MARINE SEA SPACE WAR',
  rarity: 'RARITY TABLE',
  'rarity.text': 'Enjoy the diverse and rare properties of NFT items',
  team: 'TEAM',
  'team.ceo': 'CEO',
  'team.jason': 'JASON',
  'team.cmo': 'CMO',
  'team.canlee': 'Can Lee',
  'team.designer': 'DESIGNER',
  'team.billkim': 'Bill Kim',
  'team.builder': 'Community Builder',
  'team.johnhwang': 'John Hwang',
  roadmap: 'ROADMAP',
  'roadmap.box1.title': 'August 2022',
  'roadmap.box1.subtitle': 'The Dawn of the War',
  'roadmap.box1.text1': 'The beginning of DIGIMARINE Universe',
  'roadmap.box1.text2': 'Opening the whitelisting event for DIGIMARINE community',
  'roadmap.box2.title': 'September 2022',
  'roadmap.box2.subtitle': 'The Beginning of Battles',
  'roadmap.box2.text1': 'Officially launching DIGIMARINE NFT',
  'roadmap.box2.text2': 'Providing incentives for DIGIMARINE NFT holders',
  'roadmap.box3.title': 'October 2022',
  'roadmap.box3.subtitle': 'The Climax of Interspecific War',
  'roadmap.box3.text1': 'Introducing DIGIMARINE Metaverse',
  'roadmap.box3.text2': 'Unfolding new stories and universes for DIGIMARINE',
  'roadmap.box4.title': 'November 2022',
  'roadmap.box4.subtitle': 'The Beginning of a New War',
  'roadmap.box4.text1': 'Officially launching DIGIMARINE NFT',
  'roadmap.digimarine.universe': 'A new DIGIMARINE universe',
  'roadmap.digimarine.metaverse': 'The Beginning of DIGIMARINE metaverse',
  'roadmap.step1': 'The Dawn of the War',
  'roadmap.step1.text1': 'The Beginning of DIGIMARINE Universe',
  'roadmap.step1.text2': 'Disclosing the minting page of DIGIMARINE NFT',
  'roadmap.step1.text3': 'Opening the whitelisting event of DIGIMARINE NFT',
  'roadmap.step1.text4': 'Opening an AMA event via Discord',
  'roadmap.step1.text5': 'Announcing partnership with BITMAN',
  'roadmap.step1.text6': 'Opening a special airdrop event of Mineral “B” for NFT holders',
  'roadmap.step1.text7':
    'Disclosing the official DIGIMARINE community and opening the official SNS channel',
  'roadmap.step1.text8': 'Announcing the result of whitelisting event to to participants',
  'roadmap.step1.text9': 'Announcing officially the top-tier partners (for the second time)',
  'roadmap.step1.text10': 'Disclosing the Rarity Table',
  'roadmap.step2': 'The Beginning of Battles',
  'roadmap.step2.text1': 'Disclosing the staking and reward systems of DIGIMARINE NFT',
  'roadmap.step2.text2': 'Disclosing roadmaps for future business',
  'roadmap.step2.text3': 'Opening a Youtube channel review by partnering with KOL',
  'roadmap.step2.text4': 'Opening an official AMA event via Discord',
  'roadmap.step2.text5':
    'Opening a special airdrop event of Mineral “B” for holders of multiple DIGIMARINE NFTs',
  'roadmap.step2.text6': 'Posting offline advertisements on the screen-doors of Seoul Metro',
  'roadmap.step2.text7': 'Opening a minting event',
  'roadmap.step2.text8': 'Revealing the Mystery Box',
  'roadmap.step2.text9': 'Disclosing the top-tier partners (for the second time)',
  'roadmap.step2.text10': 'Disclosing the summer-set cooperation with fashion brands',
  'roadmap.step3': 'The Climax of Interspecific War',
  'roadmap.step3.text1': 'Realizing the DIGIMARINE Metaverse Space on Sandbox',
  'roadmap.step3.text2': 'Disclosing the spinoff of DIGIMARINE stories',
  'roadmap.step3.text3':
    'Opening a Youtube channel review by partnering with KOL (for the second time)',
  'roadmap.step3.text4':
    'Opening a special airdrop event of Mineral “B” for holders of multiple DIGIMARINE NFTs',
  'roadmap.step3.text5':
    'Opening an airdrop event of BanyanTree Hotel Vouchers for holders of multiple DIGIMARINE NFTs',
  'roadmap.step3.text6':
    'Opening an event of providing discounted trading fees by working together with NFT marketplaces',
  'roadmap.step3.text7': 'Disclosing teaser clips of DIGIMARINE Metaverse Toon',
  'roadmap.step4': 'The Beginning of a New War',
  'roadmap.step4.text1': 'Announcing the new DIGIMARINE Metaverse/Universe',
  'roadmap.step4.text2': 'Disclosing the minting page of DIGIMARINE NFT (official spinoff)',
  'roadmap.step4.text3':
    'Opening an official AMA event regarding the expansion of DIGIMARINE Universe (via Discord)',
  'roadmap.step4.text4': 'Announcing officially the top-tier partners (for the third time)',
  'roadmap.step4.text5': 'Officially launching DIGIMARINE Metaverse-Toon',
  faq: 'FAQ',
  'faq.box1.question': 'What is DIGI MARINE NFT?',
  'faq.box1.answer':
    'DIGIMARINE is a series of PFP (Picture for profile) NFT. It is created based on a fantastic story of a universe where endless “Wars among Species (or Interspecific Wars)” take place. The purpose of these wars is to claim sole ownership over a very scarce resource called Mineral “B.”  The marine characters of the DIGIMARINE universe may look very cute and lovely, but in fact they are expert warriors thoroughly trained to win victory at all cost. Let’s take a look at the exciting stories and the cute-but-wild characters of DIGIMARINE Universe. The name “DIGIMARINE” comes from the words “Digital” and “Marine.” Motivated by various marine animals such as MOBYDICK, cachalots, sharks, tunas, shrimps etc, the design of each DIGIMARINE character is authentically handmade. DIGIMARINE NFT can act as a sort of membership for community members. The holders of DIGIMARINE NFT will also enjoy many benefits and incentives during their hold of NFT. For example, NFT holders will be given special access to a distinguished Telegram/Discord community channel',
  'faq.box2.question': 'How can I participate in the minting event of DIGIMARINE?',
  'faq.box2.answer':
    'There are two ways to participate in the minting event of DIGIMARINE; one is to participate in the whitelisting event, and the other is to participate in public minting. Once the NFTs are minted, you can purchase them through secondary transactions at OpenSea',
  'faq.box3.question': 'What makes DIGIMARINE NFT special?',
  'faq.box3.answer':
    'DIGIMARINE NFTs are designed based on the motif of six different species. Following the setups and concepts of the DIGIMARINE Universe, each NFT has unique properties, and because the DIGIMARINE community is made of such various species with unique properties, the holders of DIGIMARINE NFTs can enjoy great spectacles of interesting activities off-and-online.',
  'faq.box4.question': 'When will you reveal the Mystery Boxes?',
  'faq.box4.answer':
    'Mystery Boxes will be revealed around June 16, 2022 after NFT minting is completed.',
  'faq.box5.question': 'What blockchain network does DIGIMARINE use?',
  'faq.box5.answer': 'DIGIMARINE uses Ethereum network.',
  'faq.box6.question': 'How can I make use of my DIGIMARINE NFT?',
  'faq.box6.answer':
    'To activate DIGIMARINE Community to its full level, we will provide various incentives and fun-factors for the holders of DIGIMARINE NFTs. For an example, you can stake your DIGIMARINE NFT to get a special airdrop of Mineral “B.” Since each NFT species has different staking power, it is important to aim for species with staking power greater than others at the minting event. NFT holders will also enjoy access to distinguished communities. Many other events are under preparation to promote cowork and/or competition among different species.',
  footer:
    'DIGIMARINE is a series of PFP (Picture for profile) NFT. It is created based on a fantastic story of a universe where endless “Wars among Species (or Interspecific Wars)” takes place. The purpose of these wars is to claim sole ownership over a very scarce resource called Mineral “B.”  The marine characters of the DIGIMARINE universe may look very cute and lovely, but in fact they are expert warriors thoroughly trained to win victory at all cost. Let’s take a look at the exciting stories and the cute-but-wild characters of DIGIMARINE Universe.',
};
