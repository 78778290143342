import { CHANGE_LANGUAGE } from './constant';

const defaultLanguage = {
  code: 'en',
};

const currentLang = localStorage.getItem('locale') || defaultLanguage.code;

export const initialChangeLanguageState = {
  lang: currentLang,
};

export const changeLanguageReducer = (state = initialChangeLanguageState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      localStorage.setItem('locale', action.payload);
      return {
        lang: action.payload,
      };
    default:
      return state;
  }
};
