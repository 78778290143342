import React from 'react';
import './Team.scss';
import CEO from '../../image/c_art director.png';
import CMO from '../../image/c_marketer.png';
import Designer from '../../image/c_master developer.png';
import CommunityBuilder from '../../image/c_community manager.png';
import CommunityBuilder2 from '../../image/c_project manager.png';
import { useIntl } from 'react-intl';
import { CustomFade } from '../CustomFade';

export const Team = () => {
  const { formatMessage } = useIntl();
  return (
    <section className="team">
      <CustomFade bottom>
        <h1>{formatMessage({ id: 'team' })}</h1>
      </CustomFade>
      <div className="teamWrapper">
        <CustomFade bottom>
          <div className="teamMember">
            <img src={CEO} alt="ceo" />
            <span>CEO</span>
            <h2>JASON</h2>
          </div>
        </CustomFade>
        <CustomFade bottom delay={200}>
          <div className="teamMember">
            <img src={CMO} alt="cmo" />
            <span>CMO</span>
            <h2>Can Lee</h2>
          </div>
        </CustomFade>
        <CustomFade bottom delay={400}>
          <div className="teamMember">
            <img src={Designer} alt="designer" />
            <span>DESIGNER</span>
            <h2>Bill Kim</h2>
          </div>
        </CustomFade>
        <CustomFade bottom delay={600}>
          <div className="teamMember">
            <img src={CommunityBuilder} alt="community builder1" />
            <span>Community Builder</span>
            <h2>John Hwang</h2>
          </div>
        </CustomFade>
        <CustomFade bottom delay={800}>
          <div className="teamMember">
            <img src={CommunityBuilder2} alt="community builder2" />
            <span>Community Builder</span>
            <h2>JASON</h2>
          </div>
        </CustomFade>
      </div>
    </section>
  );
};
