import React from 'react';
import './Faq.scss';
import FaqData from '../../data/faq.json';
import DownArrow from '../../image/down.png';
import UpArrow from '../../image/up.png';
import { useIntl } from 'react-intl';
import { CustomFade } from '../CustomFade';

export const Faq = () => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = React.useState([false, false, false, false, false, false]);
  const handleToggle = (i) => {
    const newOpen = [...open];
    newOpen[i] = !newOpen[i];
    setOpen(newOpen);
  };
  return (
    <section className="faq">
      <div className="wrap">
        <CustomFade bottom>
          <h1>FAQ</h1>
        </CustomFade>
        <div className="faqLists">
          <ul>
            {FaqData.filter(({ index }) => index % 2 === 0).map(({ question, answer, index }) => (
              <CustomFade bottom>
                <li
                  key={index}
                  className={open[index] ? 'on' : ''}
                  onClick={() => handleToggle(index)}
                >
                  {open[index] ? (
                    <img className="arrow" src={UpArrow} alt="arrow" width="32px" />
                  ) : (
                    <img className="arrow" src={DownArrow} alt="arrow" width="32px" />
                  )}
                  <div className="question">
                    {formatMessage({ id: `faq.box${index + 1}.question` })}
                  </div>
                  <div className="answer">
                    {formatMessage({ id: `faq.box${index + 1}.answer` })}
                  </div>
                </li>
              </CustomFade>
            ))}
          </ul>
          <ul>
            {FaqData.filter(({ index }) => index % 2 !== 0).map(({ question, answer, index }) => (
              <CustomFade bottom>
                <li
                  key={index}
                  className={open[index] ? 'on' : ''}
                  onClick={() => handleToggle(index)}
                >
                  {open[index] ? (
                    <img className="arrow" src={UpArrow} alt="arrow" width="32px" />
                  ) : (
                    <img className="arrow" src={DownArrow} alt="arrow" width="32px" />
                  )}
                  <div className="question">
                    {formatMessage({ id: `faq.box${index + 1}.question` })}
                  </div>
                  <div className="answer">
                    {formatMessage({ id: `faq.box${index + 1}.answer` })}
                  </div>
                </li>
              </CustomFade>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
