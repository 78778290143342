import React from 'react';
import './Universe.scss';
import EndlessWar from '../../image/universe-3.jpg';
import Prelude from '../../image/universe-2.jpg';
import Background from '../../image/univers-1.jpg';
import UnionUp from '../../image/UnionUp.png';
import UnionDown from '../../image/UnionDown.png';
import Bubble from '../../image/bubble-3.png';
import Bubble2 from '../../image/bubble-2.png';
import { useIntl } from 'react-intl';
import { useWidth } from '../../hooks/useWidth';
import { CustomFade } from '../CustomFade';

export const Universe = () => {
  const { formatMessage } = useIntl();
  const width = useWidth();
  return (
    <section className="universe">
      <CustomFade bottom>
        <h1>DIGIMARINE UNIVERSE</h1>
      </CustomFade>
      <CustomFade bottom>
        <article className="background">
          <img src={Background} alt="Background" />
          <div className="articleDescription">
            <div className="articleHeader">
              <span>#1</span>
              <h2>Background</h2>
            </div>
            <p>{formatMessage({ id: 'universe.box1.description' })}</p>
          </div>
        </article>
      </CustomFade>
      <CustomFade bottom>
        <img src={UnionUp} alt="wavyUp" style={{ width: width > 960 ? 34 : 24 }} />
      </CustomFade>
      <CustomFade bottom>
        <article className="prelude">
          <img src={Prelude} alt="Prelude" />
          <div className="articleDescription">
            <div className="articleHeader">
              <span>#2</span>
              <h2>Prelude</h2>
            </div>
            <p>{formatMessage({ id: 'universe.box2.description' })}</p>
          </div>
        </article>
      </CustomFade>
      <CustomFade bottom>
        <img src={UnionDown} alt="wavyDown" style={{ width: width > 960 ? 34 : 24 }} />
      </CustomFade>
      <CustomFade bottom>
        <article className="endlessWar">
          <img src={EndlessWar} alt="EndlessWar" />
          <div className="articleDescription">
            <div className="articleHeader">
              <span>#3</span>
              <h2>Endless war</h2>
            </div>
            <p>{formatMessage({ id: 'universe.box3.description' })}</p>
          </div>
        </article>
      </CustomFade>
      <div className="bubble">
        <img src={Bubble} alt="bubble" />
      </div>
      <div className="bubble2">
        <img src={Bubble2} alt="bubble" />
      </div>
    </section>
  );
};
