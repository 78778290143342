import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Header,
  Main,
  Description,
  Features,
  Universe,
  EcoSystem,
  NFTB,
  RarityTable,
  Team,
  Roadmap,
  Faq,
  Footer,
} from './components';
import { languageMap } from './lang';
import { selectCurrentLanguage } from './modules';

const getTranslations = (locale) => {
  return languageMap[locale];
};

export default function App() {
  const locale = useSelector(selectCurrentLanguage);

  return (
    <IntlProvider locale={locale} messages={getTranslations(locale)} key={locale}>
      <main>
        <Header />
        <Main />
        <Description />
        <Features />
        <Universe />
        <EcoSystem />
        <NFTB />
        <RarityTable />
        <Team />
        <Roadmap />
        <Faq />
        <Footer />
      </main>
    </IntlProvider>
  );
}
