import { useEffect, useState } from 'react';

export const useScroll = () => {
  const [scroll, setScroll] = useState(0);
  const handleScroll = () => setScroll(window.scrollY);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scroll;
};
