import { memo, useEffect, useState } from 'react';
import './Select.scss';

import Arrow from '../../image/arrow.svg';

export const Select = memo(({ value, options, onChange }) => {
  const [label, setLabel] = useState('');
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (value) {
      const selectedLabel = options.find(({ key }) => key === value)?.label ?? '';
      setLabel(selectedLabel);
    }
  }, [value, options]);

  const onClick = (key) => {
    setToggle(!toggle);
    onChange(key);
  };

  return (
    <div className={`select ${toggle ? 'on' : ''}`}>
      <div className="selected" onClick={() => setToggle(!toggle)}>
        <span className="selected__label">{label}</span>
        <span className="selected__arrow">
          <img src={Arrow} alt="arrow" />
        </span>
      </div>
      {toggle && (
        <div className="options">
          {options
            .filter(({ key }) => key !== value)
            .map(({ key, label, name }) => (
              <div className="option" key={key} onClick={() => onClick(key)}>
                {name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
});
