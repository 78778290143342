import React, { useState, useEffect, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import { useScroll } from '../../hooks/useScroll';

export const CustomFade = ({ ...props }) => {
  const [show, setShow] = useState(false);
  const scroll = useScroll();
  const ref = useRef();

  useEffect(() => {
    const { height, top } = ref.current.getBoundingClientRect();
    setShow(height + top > 0);

    if (height + top > 0) {
      ref.current.classList.remove('hide');
    } else {
      ref.current.classList.add('hide');
    }
  }, [scroll]);

  return <div ref={ref}>{show ? <Fade {...props}>{props.children}</Fade> : props.children}</div>;
};
