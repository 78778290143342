import React, { useState } from 'react';
import { Button } from '../Button';
import './Description.scss';
import Bubble from '../../image/bubble-1.png';
import { useIntl } from 'react-intl';
import { Modal } from '../Modal';
import { CustomFade } from '../CustomFade';

export const Description = () => {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <>
      <section className="description">
        <CustomFade bottom>
          <p className="intro">{formatMessage({ id: 'main.description' })}</p>
        </CustomFade>
        <CustomFade bottom delay={500}>
          <div className="buttonContainer">
            <Button>{formatMessage({ id: 'view.on.opensea' })}</Button>
            <Button onClick={() => setOpen(true)}>{formatMessage({ id: 'mint.nfts' })}</Button>
          </div>
        </CustomFade>
        <div className="bubble">
          <img src={Bubble} alt="bubble" />
        </div>
      </section>
      <Modal open={open} setModal={() => setOpen(!open)} type="minting" />
    </>
  );
};
