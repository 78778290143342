import React from 'react';
import { useIntl } from 'react-intl';
import Close from '../../image/close.svg';
import Warning from '../../image/poop.png';
import { Button } from '../Button';
import { MINTING_DAY } from '../../constant';
import './Modal.scss';

export const Modal = ({ open, setModal, type }) => {
  const { formatMessage } = useIntl();

  const countDay = () => {
    const now = new Date();
    let amount = MINTING_DAY.getTime() - now.getTime();
    amount = Math.floor(amount / 1000);

    const days = Math.floor(amount / 86400);
    return `D-${days}`;
  };

  const renderModalBody = () => {
    switch (type) {
      case 'minting':
        return (
          <>
            <div className="modal__icon">
              <img src={Warning} alt="warning" />
            </div>
            <div className="title">
              {formatMessage({ id: 'modal.minting.title' })}
              {countDay()}
            </div>
            <div className="subtitle">{formatMessage({ id: 'modal.minting.subtitle' })}</div>
            <div className="text">{formatMessage({ id: 'modal.minting.text' })}</div>
            <div className="buttons">
              <Button onClick={setModal}>{formatMessage({ id: 'cancel' })}</Button>
              <Button>{formatMessage({ id: 'go.whitelist' })}</Button>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return open ? (
    <div className="modal">
      <div className="modal__container">
        <div className="modal__close">
          <button onClick={setModal}>
            <img src={Close} alt="close" />
          </button>
        </div>
        <div className="modal__body">{renderModalBody()}</div>
      </div>
      <div className="modal__backdrop" onClick={setModal}></div>
    </div>
  ) : (
    <></>
  );
};
