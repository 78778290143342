import React from 'react';
import './EcoSystem.scss';
import Dolphin from '../../image/DOLPHIN--image.png';
import Shark from '../../image/SHARK-image.png';
import Shrimp from '../../image/SHRIMP-image.png';
import Tuna from '../../image/TUNA-image.png';
import Spermwale from '../../image/SPERM WALE-image.png';
import Bluewhale from '../../image/BLUE WHALE-image.png';
import Arrow1 from '../../image/arrow1.png';
import Arrow2 from '../../image/arrow2.png';
import Arrow3 from '../../image/arrow3.png';
import Arrow4 from '../../image/arrow4.png';
import Arrow5 from '../../image/arrow5.png';
import Arrow6 from '../../image/arrow6.png';
import Bubble from '../../image/bubble-5.png';
import { useIntl } from 'react-intl';
import { useWidth } from '../../hooks/useWidth';
import Eco1 from '../../image/eco-1.png';
import Eco2 from '../../image/eco-2.png';
import Eco3 from '../../image/eco-3.png';
import Eco4 from '../../image/eco-4.png';
import Eco5 from '../../image/eco-5.png';
import Eco6 from '../../image/eco-6.png';
import { CustomFade } from '../CustomFade';

const Item = ({ src, name }) => {
  const { formatMessage } = useIntl();
  return (
    <CustomFade left>
      <div className="ecosystemWrapper__item">
        <div className="img">
          <img src={src} alt={name} />
        </div>
        <div className="content">
          <div className="content__title">{formatMessage({ id: `ecosystem.${name}` })}</div>
          <div className="content__description">
            {formatMessage({ id: `ecosystem.${name}.description` })}
          </div>
        </div>
      </div>
    </CustomFade>
  );
};

export const EcoSystem = () => {
  const { formatMessage } = useIntl();
  const width = useWidth();

  return (
    <section className="ecosystem">
      <CustomFade bottom>
        <h1>{formatMessage({ id: 'ecosystem' })}</h1>
      </CustomFade>
      <div className="ecosystemWrapper">
        {width > 1320 ? (
          <>
            <div className="ecoImgWrapper">
              <CustomFade left>
                <img src={Bluewhale} alt="bluewhale" />
              </CustomFade>
              <CustomFade left>
                <img src={Spermwale} alt="spermwale" />
              </CustomFade>
              <CustomFade left>
                <img src={Shark} alt="shark" />
              </CustomFade>
              <CustomFade left>
                <img src={Dolphin} alt="dolphin" style={{ marginBottom: 10 }} />
              </CustomFade>
              <CustomFade left>
                <img src={Tuna} alt="tuna" style={{ marginBottom: 15 }} />
              </CustomFade>
              <CustomFade left>
                <img src={Shrimp} alt="shrimp" />
              </CustomFade>
            </div>
            <div className="ecosystemDescription">
              <CustomFade right>
                <div className="blueWhaleIntro">
                  <img src={Arrow1} alt="arrow1" style={{ width: 46 }} />
                  <h2 className="blueWhale">{formatMessage({ id: 'ecosystem.whale' })}</h2>
                  <p>{formatMessage({ id: 'ecosystem.whale.description' })}</p>
                </div>
              </CustomFade>
              <CustomFade right>
                <div className="spermWaleIntro">
                  <img src={Arrow2} alt="arrow2" style={{ width: 46 }} />
                  <h2 className="spermWale">{formatMessage({ id: 'ecosystem.sperm' })}</h2>
                  <p>{formatMessage({ id: 'ecosystem.sperm.description' })}</p>
                </div>
              </CustomFade>
              <CustomFade right>
                <div className="sharkIntro">
                  <img src={Arrow3} alt="arrow3" style={{ width: 46 }} />
                  <h2 className="shark">SHARK</h2>
                  <p>{formatMessage({ id: 'ecosystem.shark.description' })}</p>
                </div>
              </CustomFade>
              <CustomFade right>
                <div className="dolphinIntro">
                  <img src={Arrow4} alt="arrow4" style={{ width: 46 }} />
                  <h2 className="dolphin">DOLPHIN</h2>
                  <p>{formatMessage({ id: 'ecosystem.dolphin.description' })}</p>
                </div>
              </CustomFade>
              <CustomFade right>
                <div className="tunaIntro">
                  <img src={Arrow5} alt="arrow5" style={{ width: 46 }} />
                  <h2 className="tuna">TUNA</h2>
                  <p>{formatMessage({ id: 'ecosystem.tuna.description' })}</p>
                </div>
              </CustomFade>
              <CustomFade right>
                <div className="shrimpIntro">
                  <img src={Arrow6} alt="arrow6" style={{ width: 46 }} />
                  <h2 className="shrimp">SHRIMP</h2>
                  <p>{formatMessage({ id: 'ecosystem.shrimp.description' })}</p>
                </div>
              </CustomFade>
            </div>
          </>
        ) : (
          <>
            <Item src={Eco1} name="whale" />
            <Item src={Eco2} name="sperm" />
            <Item src={Eco3} name="shark" />
            <Item src={Eco4} name="dolphin" />
            <Item src={Eco5} name="tuna" />
            <Item src={Eco6} name="shrimp" />
          </>
        )}
      </div>
      <div className="bubble">
        <img src={Bubble} alt="bubble" />
      </div>
    </section>
  );
};
