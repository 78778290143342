import React, { useState } from 'react';
import './NFTB.scss';
import Shrimp from '../../image/avatar_shrimp.png';
import Tuna from '../../image/avatar_tuna.png';
import Dolphin from '../../image/avatar_dolphin.png';
import Shark from '../../image/avatar_shark.png';
import SpermWale from '../../image/avatar_spermwale.png';
import BlueWhale from '../../image/avatar_bluewhale.png';
import { Button } from '..';
import Bubble from '../../image/bubble-6.png';
import { useIntl } from 'react-intl';
import { Modal } from '../Modal';
import { CustomFade } from '../CustomFade';

export const NFTB = () => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  return (
    <>
      <section className="nftb">
        <CustomFade bottom>
          <h1>{formatMessage({ id: 'nftb' })}</h1>
        </CustomFade>
        <div className="nftbWrapper">
          <CustomFade bottom>
            <article className="nftbShrimp">
              <div className="shrimpStory">
                <h2>SHRIMP</h2>
                <p>
                  DIGIMARINE
                  <br />
                  SEA SPACE WAR
                </p>
              </div>
              <img src={Shrimp} alt="shrimp" />
            </article>
          </CustomFade>
          <CustomFade bottom delay={200}>
            <article className="nftbTuna">
              <div className="tunaStory">
                <h2>TUNA</h2>
                <p>
                  DIGIMARINE
                  <br />
                  SEA SPACE WAR
                </p>
              </div>
              <img src={Tuna} alt="tuna" />
            </article>
          </CustomFade>
          <CustomFade bottom delay={400}>
            <article className="nftbDolphin">
              <div className="dolphinStory">
                <h2>DOLPHIN</h2>
                <p>
                  DIGIMARINE
                  <br />
                  SEA SPACE WAR
                </p>
              </div>
              <img src={Dolphin} alt="dolphin" />
            </article>
          </CustomFade>
          <CustomFade bottom delay={600}>
            <article className="nftbShark">
              <div className="sharkStory">
                <h2>SHARK</h2>
                <p>
                  DIGIMARINE
                  <br />
                  SEA SPACE WAR
                </p>
              </div>
              <img src={Shark} alt="shark" />
            </article>
          </CustomFade>
          <CustomFade bottom delay={800}>
            <article className="nftbSpermWale">
              <div className="spermWaleStory">
                <h2>CACHALOT</h2>
                <p>
                  DIGIMARINE
                  <br />
                  SEA SPACE WAR
                </p>
              </div>
              <img src={SpermWale} alt="spermwale" />
            </article>
          </CustomFade>
          <CustomFade bottom delay={1000}>
            <article className="nftbBlueWhale">
              <div className="blueWhaleStory">
                <h2>MOBYDICK</h2>
                <p>
                  DIGIMARINE
                  <br />
                  SEA SPACE WAR
                </p>
              </div>
              <img src={BlueWhale} alt="bluewhale" />
            </article>
          </CustomFade>
        </div>
        <CustomFade bottom delay={1200}>
          <div className="buttonContainer">
            <Button>{formatMessage({ id: 'view.on.opensea' })}</Button>
            <Button onClick={() => setOpen(true)}>{formatMessage({ id: 'mint.nfts' })}</Button>
          </div>
        </CustomFade>
        <div className="bubble">
          <img src={Bubble} alt="bubble" />
        </div>
      </section>
      <Modal open={open} setModal={() => setOpen(!open)} type="minting" />
    </>
  );
};
