import React from 'react';
import './Roadmap.scss';
import DotIconMint from '../../image/dotIconMint.png';
import DotIconBlack from '../../image/dotIconBlack.png';
import RoadmapArrow from '../../image/roadmapArrow.png';
import { Button } from '..';
import Bubble from '../../image/bubble-7.png';
import Bubble2 from '../../image/bubble-3.png';
import { useIntl } from 'react-intl';
import { CustomFade } from '../CustomFade';

const RoadmapItem = ({ index, month }) => {
  const { formatMessage } = useIntl();
  const thisMonth = (mm) => {
    const now = new Date().getMonth();
    return mm === now;
  };
  return (
    <CustomFade bottom delay={index * 200}>
      <div className={thisMonth(month) ? 'thisMonth' : ''}>
        <span>#{index}</span>
        <h2>{formatMessage({ id: `roadmap.box${index}.title` })}</h2>
        <span>{formatMessage({ id: `roadmap.box${index}.subtitle` })}</span>
        <img src={thisMonth(month) ? DotIconMint : DotIconBlack} alt="dot" />
        <p>{formatMessage({ id: `roadmap.box${index}.text1` })}</p>
        {index !== 4 && (
          <>
            <img src={thisMonth(month) ? DotIconMint : DotIconBlack} alt="dot" />
            <p>{formatMessage({ id: `roadmap.box${index}.text2` })}</p>
          </>
        )}
      </div>
    </CustomFade>
  );
};

export const Roadmap = () => {
  const { formatMessage } = useIntl();

  return (
    <section className="roadmap">
      <CustomFade bottom>
        <h1>{formatMessage({ id: 'roadmap' })}</h1>
      </CustomFade>
      <div className="history">
        <RoadmapItem index={1} month={7} />
        <RoadmapItem index={2} month={8} />
        <RoadmapItem index={3} month={9} />
        <RoadmapItem index={4} month={10} />
      </div>
      <CustomFade bottom>
        <div className="down">
          <img src={RoadmapArrow} alt="roadmapArrow" />
        </div>
      </CustomFade>
      <div className="roadmapTableWrapper">
        <CustomFade bottom>
          <div className="roadmapTable">
            <div className="roadmapTableInner">
              <h3>{formatMessage({ id: 'roadmap.step1' })}</h3>
              <Button>{formatMessage({ id: 'roadmap.digimarine.universe' })}</Button>
              <ul>
                <li>{formatMessage({ id: 'roadmap.step1.text1' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text2' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text3' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text4' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text5' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text6' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text7' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text8' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text9' })}</li>
                <li>{formatMessage({ id: 'roadmap.step1.text10' })}</li>
              </ul>
            </div>
          </div>
        </CustomFade>
        <CustomFade bottom delay={200}>
          <div className="roadmapTable">
            <div className="roadmapTableInner">
              <h3>{formatMessage({ id: 'roadmap.step2' })}</h3>
              <Button>{formatMessage({ id: 'roadmap.digimarine.universe' })}</Button>
              <ul>
                <li>{formatMessage({ id: 'roadmap.step2.text1' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text2' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text3' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text4' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text5' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text6' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text7' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text8' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text9' })}</li>
                <li>{formatMessage({ id: 'roadmap.step2.text10' })}</li>
              </ul>
            </div>
          </div>
        </CustomFade>
        <CustomFade bottom delay={400}>
          <div className="roadmapTable">
            <div className="roadmapTableInner">
              <h3>{formatMessage({ id: 'roadmap.step3' })}</h3>
              <Button>{formatMessage({ id: 'roadmap.digimarine.metaverse' })}</Button>
              <ul>
                <li>{formatMessage({ id: 'roadmap.step3.text1' })}</li>
                <li>{formatMessage({ id: 'roadmap.step3.text2' })}</li>
                <li>{formatMessage({ id: 'roadmap.step3.text3' })}</li>
                <li>{formatMessage({ id: 'roadmap.step3.text4' })}</li>
                <li>{formatMessage({ id: 'roadmap.step3.text5' })}</li>
                <li>{formatMessage({ id: 'roadmap.step3.text6' })}</li>
                <li>{formatMessage({ id: 'roadmap.step3.text7' })}</li>
              </ul>
            </div>
          </div>
        </CustomFade>
        <CustomFade bottom delay={600}>
          <div className="roadmapTable">
            <div className="roadmapTableInner">
              <h3>{formatMessage({ id: 'roadmap.step4' })}</h3>
              <Button>{formatMessage({ id: 'roadmap.digimarine.universe' })}</Button>
              <ul>
                <li>{formatMessage({ id: 'roadmap.step4.text1' })}</li>
                <li>{formatMessage({ id: 'roadmap.step4.text2' })}</li>
                <li>{formatMessage({ id: 'roadmap.step4.text3' })}</li>
                <li>{formatMessage({ id: 'roadmap.step4.text4' })}</li>
                <li>{formatMessage({ id: 'roadmap.step4.text5' })}</li>
              </ul>
            </div>
          </div>
        </CustomFade>
      </div>
      <div className="bubble">
        <img src={Bubble} alt="" />
      </div>
      <div className="bubble2">
        <img src={Bubble2} alt="" />
      </div>
    </section>
  );
};
