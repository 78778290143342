export const ko = {
  cancel: '취소',
  'go.whitelist': '화이트리스트 참여하기',
  'modal.minting.title': '민팅하기 ',
  'modal.minting.subtitle': '화이트리스트에 참여를 희망하시나요?',
  'modal.minting.text':
    '디지마린 화이트리스트 등록하신다면\n디지마린NFT를 민팅하실 뿐만 아니라\n미네랄 B를 에어드롭 받으실 수 있습니다.',
  'main.description':
    '디지마린은 PFP(Picture for profile) NFT 시리즈입니다.디지마린 유니버스는 희귀자원 광물인 미네랄 “B”을 둘러싼 치열하고 끝없는 종간 전쟁을 다루고 있습니다. 디지마린의 종들은 매우 귀여운 모습을 하고 있지만, 그들은 모두 승리를 위해 철저하게 훈련받은 전문적인 전사들입니다. 사랑스럽고 귀엽지만 거친 반전 매력을 가진 디지마린의 다이나믹한 스토리와 캐릭터에 한 번 빠져볼까요.',
  'view.on.opensea': '오픈시에서 보기',
  'mint.nfts': 'NFT 민팅하기',
  features: 'FEATURES',
  'features.hand': 'HAND - DRAWN ART',
  'features.description':
    '디지마린은 수작업 디자인 기반의 PFP NFT 시리즈물입니다. 흰수염고래, 고래, 상어, 돌고래, 참치, 새우 등 여섯가지의 해양 동물을 모티브로 만들어진 캐릭터들이 그 주인공입니다. 차별화된 10,000개의 유니크한 특성을 지닌 NFT 캐릭터들은 디지마린 유니버스에서 격렬한 전투를 치루는 전사들입니다. 이런 디지마린의 유니버스가 마치 암호화폐 생태계에서의 모습을 반영한 것 같기도합니다.\n\n동시에 디지마린의 캐릭터들은 희귀자원 광물 “B”를 얻기 위해 항상 치열하게 전쟁을 치루고 있습니다. 희귀자원 광물 “B”는 도대체 무엇일까요? 이제 그 궁금증이 곧 밝혀질 것 같습니다.',
  universe: 'DIGIMARINE UNIVERSE',
  'universe.box1.title': 'Background',
  'universe.box1.description':
    '서기 3,802년, 지구로부터 약 6광년 정도 떨어진 디지어쓰(Digi-Earth) 행성은 푸른 바다로 뒤덮힌 메타버스 행성입니다. 고도로 발달한 과학기술과 문명이 디지어쓰의 자원을 급속도로 고갈시켰으며, 마침내 디지어쓰는 행성의 모든 생명체의 생존을 위협하는 대기근을 맞이하게 됩니다.',
  'universe.box2.title': 'Prelude',
  'universe.box2.description':
    '디지어쓰에 살아남은 고도로 문명화된 몇몇 종족들은 얼마남지 않은 자원을 둘러싸고 치열한 전투에 돌입하게 되었습니다. 이러한 상황에서 익명의 광물학자가 무한한 에너지를 제공할 수 있는 희귀자원 광물 “B”를 발견했다는 소문이 돌기 시작했고, 이윽고 모든 디지어쓰의 종족들이 희귀자원 광물 “B”를 찾아내고 독차지하기 위한 쟁탈전에 돌입하게 됩니다.',
  'universe.box3.title': 'Endless war',
  'universe.box3.description':
    '기나긴 종간 정쟁을 거쳐 오직 6종만이 살아남게 되었습니다. 과연 어떤 종족이 미네랄 “B”를 차지하게 될까요? 최후의 전쟁을 알리는 총성이 울려 퍼지기 시작했습니다.',
  ecosystem: '에코시스템',
  'ecosystem.whale': 'MOBYDICK',
  'ecosystem.whale.description':
    '대왕고래는 디지마린 생태계에서 최상위 포식자입니다.\n매우 강력하고 커다른 몸집은 모든 것을 일순간에 파괴할 수 있을 정도로\n막강한 힘을 갖고 있습니다. \n대왕고래가 나타나면 모든 생명체들이 두려움에 빠질 정도입니다.',
  'ecosystem.sperm': 'CACHALOT',
  'ecosystem.sperm.description':
    '향유고래의 힘과 파괴력은 대왕고래만큼은 아니지만,\n다른 종족들을 사냥하기에 부족하지 않습니다.\n그들의 큰 입은 주변에 있는 거의 모든 것을 빨아 들입니다.\n그들은 대왕고래와 종종 어울리기도 하고 자주 만나서 교류를 하기도 합니다.',
  'ecosystem.shark': 'SHARK',
  'ecosystem.shark.description':
    '상어는 다른 생명체를 사냥하기에 최적화된 종족입니다.\n심지어 때로는 고래들도 잡아먹습니다. 그들은 겁이 없고 매우 파괴적입니다.\n그들이 전투에 돌입하기 시작하면, 그 누구도 그들을 막을 수 없습니다.',
  'ecosystem.dolphin': 'DOLPHIN',
  'ecosystem.dolphin.description':
    '돌고래 종족은 매우 똑똑하며 협력심이 있어 다른 돌고래들과 함께 무리를 지어 행동합니다.\n그들은 집단적으로 전투에 임하기 때문에\n심지어 대왕고래도 쉽사리 이길 수 없는 존재들입니다.\n그들은 디지어쓰에서 가장 타고난 전략가들입니다.\n바다에서 홀로 다니는 대왕고래들 조차도 돌고래에 종종 사냥당하는 모습이 보이곤 합니다.',
  'ecosystem.tuna': 'TUNA',
  'ecosystem.tuna.description':
    '참치는 디지어쓰 바다에서 가장 빠르고 생명력이 강합니다.\n엄청난 에너지로 매우 긴 시간동안 쉬지 않고 수영을 할 수 있고,\n타 종족이 상상하기 힘든 거리를 빠르게 움직이는\n막강한 기동성을 보유하고 있습니다.',
  'ecosystem.shrimp': 'SHRIMP',
  'ecosystem.shrimp.description':
    '새우 종족은 다른 종족들과의 전투에서\n그들을 무력으로 제압할 수 는 없지만 막강한 생존력을 갖고 있으며,\n전투에서 도망치고 숨는 탁월한 능력을 보유하고 있고,\n다른 새우 종족과 협력을 잘하기에\n생존과 개체수의 증가에 탁월한 능력을 보여주고 있습니다.',
  nftb: 'NFT를 스테이킹하고\n희귀자원 광물 “B”\n얻어보자!',
  'nftb.shrimp': 'SHRIMP',
  'nftb.tuna': 'TUNA',
  'nftb.dolphin': 'DOLPHIN',
  'nftb.shark': 'SHARK',
  'nftb.sperm': 'CACHALOT',
  'nftb.whale': 'MOBYDICK',
  'digimarine.sea.story': 'DIGI MARINE SEA STORY',
  rarity: '레어리티 테이블',
  'rarity.text': 'NFT아이템의 다양한 특성과 다양성을 즐겨보세요!',
  team: '팀',
  'team.ceo': 'CEO',
  'team.jason': 'JASON',
  'team.cmo': 'CMO',
  'team.canlee': 'Can Lee',
  'team.designer': 'DESIGNER',
  'team.billkim': 'Bill Kim',
  'team.builder': 'Community Builder',
  'team.johnhwang': 'John Hwang',
  roadmap: '로드맵',
  'roadmap.box1.title': '2022년 8월',
  'roadmap.box1.subtitle': '전쟁의 서막',
  'roadmap.box1.text1': '디지마린 유니버스의 시작',
  'roadmap.box1.text2': '디지마린 커뮤니티 기반의 화이트리스트 이벤트 진행',
  'roadmap.box2.title': '2022년 9월',
  'roadmap.box2.subtitle': '전투의 시작',
  'roadmap.box2.text1': '디지마린 NFT의 공식 런칭',
  'roadmap.box2.text2': '디지마린 NFT 홀더에 대한 인센티브 제공',
  'roadmap.box3.title': '2022년 10월',
  'roadmap.box3.subtitle': '정점으로 치닫는 종간전쟁',
  'roadmap.box3.text1': '디지마린 메타버스의 등장',
  'roadmap.box3.text2': '새로운 디지마린 스토리와 유니버스의 전개',
  'roadmap.box4.title': '2022년 11월',
  'roadmap.box4.subtitle': '새로운 전쟁의 시작',
  'roadmap.box4.text1': '디지마린 NFT의 공식 런칭',
  'roadmap.digimarine.universe': '새로운 디지마린 유니버스',
  'roadmap.digimarine.metaverse': '디지마린 메타버스의 시작',
  'roadmap.step1': '전쟁의 서막',
  'roadmap.step1.text1': '디지마린 유니버스의 시작',
  'roadmap.step1.text2': '디지마린 NFT 민팅 페이지 공개',
  'roadmap.step1.text3': '디지마린 NFT 화이트리스팅 이벤트 진행',
  'roadmap.step1.text4': '디스코드 기반 AMA 이벤트 진행',
  'roadmap.step1.text5': 'BITMAN 파트너십 발표',
  'roadmap.step1.text6': 'NFT홀더에 대한 미네랄 “B” 스페셜 에어드롭 이벤트',
  'roadmap.step1.text7': '디지마린 공식 커뮤니티 공개 및 SNS 채널 오픈',
  'roadmap.step1.text8': '화이트리스팅 참여자 결과 발표',
  'roadmap.step1.text9': '탑티어 파트너사 공식 발표',
  'roadmap.step1.text10': '레어리티 테이블 공개',
  'roadmap.step2': '전투의 시작',
  'roadmap.step2.text1': '디지마린 NFT 기반 스테이킹 및 보상 시스템 공개',
  'roadmap.step2.text2': '향후 비지니스 로드맵 공개',
  'roadmap.step2.text3': 'KOL 협업 유튜브 채널 리뷰 진행',
  'roadmap.step2.text4': '공식 디스코드 AMA 이벤트 진행',
  'roadmap.step2.text5': '디지마린 NFT “찐” 홀더 대상 스페셜 미네랄 “B” 에어드롭 이벤트 진행',
  'roadmap.step2.text6': '서울 지하철 스크린도어 오프라인 광고 진행',
  'roadmap.step2.text7': '민팅 이벤트',
  'roadmap.step2.text8': '미스터리 박스 리빌',
  'roadmap.step2.text9': '두 번째 탑티어 파트너사의 공개',
  'roadmap.step2.text10': '패션 브랜드와 섬머셋 협업 공개',
  'roadmap.step3': '정점으로 치닫는 종간전쟁',
  'roadmap.step3.text1': '샌드박스에서의 디지마린 메타버스 공간 구현',
  'roadmap.step3.text2': '디지마린 스토리의 스핀오프 공개',
  'roadmap.step3.text3': 'KOL 협업 유튜브 채널 리뷰',
  'roadmap.step3.text4': '디지마린 NFT “찐” 홀더 대상 스페셜 에어드롭 이벤트 ',
  'roadmap.step3.text5': '디지마린 NFT “찐” 홀더 대상 반얀트리 호텔 바우처 에어드롭 이벤트',
  'roadmap.step3.text6': 'NFT마켓플레이스와 협업을 통한 거래 수수료 절감 이벤트 진행',
  'roadmap.step3.text7': '디지마린 메타버스툰의 티저 공개',
  'roadmap.step4': '새로운 전쟁의 시작',
  'roadmap.step4.text1': '새로운 디지마린 메타버스 유니버스 공개',
  'roadmap.step4.text2': '공식적인 스핀오프 디지마린 NFT 민팅 페이지 공개',
  'roadmap.step4.text3': '디지마린 유니버스 확장을 위한 디스코드 AMA 이벤트 진행',
  'roadmap.step4.text4': '세번째 탑티어 파트너사 공개',
  'roadmap.step4.text5': '디지마린 메타버스툰 공식 런칭',
  faq: 'FAQ',
  'faq.box1.question': 'DIGIMARINE NFT는 무엇인가요?',
  'faq.box1.answer':
    '디지마린은 가치있는 희귀자원 광물 “B”를 둘러싼 끝없는 디지어쓰 종간 전쟁을 테마로 한 유니버스를 배경으로 흥미롭고 재미있는 스토리를 가진 PFP NFT 시리즈입니다. 바다 생명체들은 매우 귀엽고 사랑스럽지만, 그들은 오로지 전투의 승리만을 위한 전문적인 전투가들입니다. 귀엽지만 와일드한 디지마린의 캐릭터와 흥미로운 스토리를 한 번 즐겨볼까요!\n디지마린은 “Digital”과 “Marine”의 합성어로 고래, 상어, 참치, 새우 등 다양한 해양동물을 모티브로 하여 만들어진 수작업 디자인 일러스트를 통해 만들어졌습니다. 각각의 캐릭터마다 고유하고 창의적인 일러스트 디자인이 적용되어 있습니다. 디지마린 NFT는 커뮤니티의 회원들에게는 일종의 회원권이며, 디지마린 NFT 홀더에게는 다양한 혜택과 인센티브가 지속적으로 제공될 예정입니다. 예를 들어, NFT홀더들은 차별화된 텔레그램 혹은 디스코드 커뮤니티에 참여할 수 있는 권한을 갖게 될 것입니다.',
  'faq.box2.question': 'DIGIMARINE 민팅 이벤트에 어떻게 참여 가능한가요?',
  'faq.box2.answer':
    '디지마린 NFT를 민팅하기 위해서는 화이트리스팅 이벤트에 참여하는 방법과 퍼블릭 민팅에 참여하는 방법이 이있습니다. NFT가 민팅된 이후에는 오픈시에서 2차 거래를 통해 구매하실 수도 있습니다.',
  'faq.box3.question': 'DIGIMARINE NFT의 차별점은 무엇인가요?',
  'faq.box3.answer':
    'DIGIMARINE NFT는 총 6가지의 다양한 종족으로 구성되어 있습니다. 디지마린 유니버스의 설정과 컨셉에 따라서 각각의 NFT는 독특한 특성을 갖고 있습니다. 다양한 종족으로 구성되어 디지마린의 커뮤니티에서 종족간의 협업, 경쟁 등 유저들이 온라인과 오프라인을 넘어서 더욱 재미있고 흥미로운 활동들이 가능하도록 활기를 불어넣을 수 있을 것입니다.',
  'faq.box4.question': '미스터리 박스는 언제 공개될 예정인가요?',
  'faq.box4.answer': '미스터리 박스는 NFT 민팅이 모두 종료된 이후에 6월 16일 경 진행될 예정입니다.',
  'faq.box5.question': 'DIGIMARINE은 어떤 블록체인 네트워크를 활용하나요?',
  'faq.box5.answer': '디지마린은 이더리움 네트워크를 이용합니다.',
  'faq.box6.question': 'DIGIMARINE NFT는 어떻게 활용되나요?',
  'faq.box6.answer':
    '무엇보다도 디지마린 커뮤니티의 활성화를 위해서 NFT홀더에게 다양한 인센티브와 재미요소를 제공하고자 노력할 것입니다. 예를 들어, 디지마린 NFT를 보유한 후 스테이킹을 할 경우, 희귀자원 광물 “B”를 에어드랍 받을 수 있습니다. 각각의 종족마다 다른 스테이킹 파워를 갖고 있기 때문에 민팅에서 최대한의 에어드랍을 받을 수 있는 높은 스테이킹 파워의 종족을 얻는 것이 무엇보다 중요합니다. 이외에도 NFT 홀더들에게 차별화된 커뮤니티를 제공하고, 종간 협업 및 경쟁을 위한 다양한 이벤트를 준비하고 있습니다.',
  footer:
    '디지마린은 PFP(Picture for profile) NFT 시리즈입니다. 디지마린 유니버스는 희귀자원 광물인 미네랄 “B”을 둘러싼 치열하고 끝없는 종간 전쟁을 다루고 있습니다. 디지마린의 종들은 매우 귀여운 모습을 하고 있지만, 그들은 모두 승리를 위해 철저하게 훈련받은 전문적인 전사들입니다. 사랑스럽고 귀엽지만 거친 반전 매력을 가진 디지마린의 다이나믹한 스토리와 캐릭터에 한 번 빠져볼까요.',
};
