import React from 'react';
import './Footer.scss';
import Logo from '../../image/logo.png';
import Twitter from '../../image/Twitter.svg';
import Telegram from '../../image/Telegram.svg';
import Discord from '../../image/Discord.svg';
import { useIntl } from 'react-intl';

export const Footer = () => {
  const { formatMessage } = useIntl();
  return (
    <section className="footer">
      <div className="wrap">
        <img src={Logo} alt="logo" width="189px" />
        <div className="inner">
          <p>{formatMessage({ id: 'footer' })}</p>
          <div className="connect">
            <button onClick={() => window.open('https://twitter.com/digimarine_k')}>
              <img src={Twitter} alt="twitter" width="32px" />
            </button>
            <button onClick={() => window.open('https://t.me/digimarineofficial')}>
              <img src={Telegram} alt="telegram" width="32px" />
            </button>
            <button onClick={() => window.open('https://discord.gg/QKHBznCaZ3')}>
              <img src={Discord} alt="discord" width="32px" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
