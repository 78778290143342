import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, selectCurrentLanguage } from '../../modules';
import { Select } from '../Select';
import './Header.scss';

export const Header = () => {
  const locale = useSelector(selectCurrentLanguage);
  const dispatch = useDispatch();

  const handleLocales = (lang) => {
    dispatch(changeLanguage(lang));
  };

  return (
    <header>
      <div className="wrap">
        <Select
          value={locale}
          onChange={handleLocales}
          options={[
            { key: 'ko', label: 'KOR', name: 'KOREAN' },
            { key: 'en', label: 'ENG', name: 'ENGLISH' },
          ]}
        />
      </div>
    </header>
  );
};
