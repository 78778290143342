import React from 'react';
import './Button.scss';

export const Button = React.memo(({ ...props }) => {
  return (
    <button {...props} ref={props.submit} className={`button`}>
      {props.children}
    </button>
  );
});

Button.displayName = 'Button';
