import React from 'react';
import './Features.scss';
import Bubble from '../../image/bubble-2.png';
import GIF from '../../image/features-gif.gif';

import { useIntl } from 'react-intl';
import { useWidth } from '../../hooks/useWidth';
import { CustomFade } from '../CustomFade';

export const Features = () => {
  const { formatMessage } = useIntl();
  const width = useWidth();
  return (
    <section className="features">
      <div className="featuresContainer">
        <div className="featuresDescription">
          <CustomFade bottom>
            <h1>FEATURES</h1>
          </CustomFade>
          <CustomFade bottom delay={500}>
            <h2>HAND - DRAWN ART</h2>
          </CustomFade>
          {width > 960 && (
            <CustomFade bottom delay={1000}>
              <p>{formatMessage({ id: 'features.description' })}</p>
            </CustomFade>
          )}
        </div>
        <CustomFade right delay={1200}>
          <div className="featuresAvatar">
            <div className="featuresAvatar__wrapper">
              <img src={GIF} alt="digimarine" />
            </div>
          </div>
        </CustomFade>
        {width <= 960 && (
          <CustomFade bottom delay={1200}>
            <p>{formatMessage({ id: 'features.description' })}</p>
          </CustomFade>
        )}
      </div>
      <div className="bubble">
        <img src={Bubble} alt="bubble" />
      </div>
    </section>
  );
};
