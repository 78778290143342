import React, { useEffect, useRef } from 'react';
// import Logo from '../../image/main-text.png';
import Logo1 from '../../image/main-logo.png';
import Logo2 from '../../image/main-logo2.png';
import Main1 from '../../image/main-1.png';
import Main2 from '../../image/main-2.png';
import Back1 from '../../image/bg-stone01.png';
import Back2 from '../../image/bg-stone02.png';
import Back3 from '../../image/bg-stone03.png';
import Leaf1 from '../../image/bg-leaf01.png';
import Leaf2 from '../../image/bg-leaf02.png';
import './Main.scss';
import { Fade } from 'react-reveal';
import { useScroll } from '../../hooks/useScroll';

const BackItem = ({ src, index }) => {
  return (
    <Fade bottom delay={index * 150}>
      <img src={src} alt="digimarine" />
    </Fade>
  );
};

export const Main = () => {
  const list = [Back3, Back2, Back1, Leaf2, Leaf1];
  const scroll = useScroll();
  const ref = useRef();

  useEffect(() => {
    const { height, top } = ref.current.getBoundingClientRect();
    if (height + top > 0) {
      ref.current.classList.add('animation');
    } else {
      ref.current.classList.remove('animation');
    }
  }, [scroll]);

  return (
    <section className="main">
      <div className="main__logo" ref={ref}>
        <img className="main1" src={Main1} alt="digimarine" />
        <img className="main2" src={Main2} alt="digimarine" />
        {/* <img className="logo" src={Logo} alt="digimarine" /> */}
        <div>
          <img className="logo1" src={Logo1} alt="digimarine" />
          <img className="logo2" src={Logo2} alt="digimarine" />
        </div>
      </div>
      <div className="main__background">
        {list.map((e, i) => (
          <BackItem key={i} src={e} index={i} />
        ))}
      </div>
    </section>
  );
};
