import React, { useEffect, useState } from 'react';
import './RarityTable.scss';
import Down from '../../image/down.svg';
import Bubble from '../../image/bubble-7.png';
import Bubble2 from '../../image/bubble-8.png';
import { useIntl } from 'react-intl';
import Data from '../../data/rarity.json';
import { useWidth } from '../../hooks/useWidth';
import { CustomFade } from '../CustomFade';

const Item = ({ data, opened }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  return (
    <CustomFade bottom>
      <li className={open ? 'on' : ''} onClick={() => setOpen(!open)}>
        <div className="top">
          <div>{data.title}</div>
          <img className="arrow" src={Down} alt="arrow" />
        </div>
        <div className="boxs">
          {data.list.map(({ name, content }) => (
            <div className="box" key={name}>
              <div className="box__name">{name}</div>
              <div className="box__contents">
                {content.map(({ type, percentage }) => (
                  <div className="box__content" key={type}>
                    <div>{type}</div>
                    <span>{percentage}%</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </li>
    </CustomFade>
  );
};

export const RarityTable = () => {
  const { formatMessage } = useIntl();
  const width = useWidth();

  return (
    <section className="rarityTable">
      <div className="rarityTableHeader">
        <CustomFade bottom>
          <h1>{formatMessage({ id: 'rarity' })}</h1>
        </CustomFade>
        <CustomFade bottom delay={200}>
          <h2>{formatMessage({ id: 'rarity.text' })}</h2>
        </CustomFade>
        <div className="rarityTableItems">
          {width > 960 ? (
            <>
              <ul>
                <Item data={Data[0]} opened={true} />
                <Item data={Data[3]} opened={false} />
              </ul>
              <ul>
                <Item data={Data[1]} opened={true} />
                <Item data={Data[4]} opened={false} />
              </ul>
              <ul>
                <Item data={Data[2]} opened={true} />
                <Item data={Data[5]} opened={false} />
              </ul>
            </>
          ) : (
            <ul>
              {Data.map((e, i) => (
                <Item data={e} key={i} opened={i === 0} />
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="bubble">
        <img src={Bubble} alt="bubble" />
      </div>
      <div className="bubble2">
        <img src={Bubble2} alt="bubble" />
      </div>
    </section>
  );
};
